@import "./src/sass/index.scss";

.merch-detail-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;

  .dropdown-container {
    margin-top: 15px;
    margin-bottom: 7px;
    justify-content: flex-end;
    display: inline-block;
    width: 61%;
    align-self: center;

    .dropdown-column {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      flex-basis: 60%;
      justify-content: center;
      align-items: center;
      margin: 5px 0px;

      .dropdown-row {
        width: 100%;
        font-weight: 100;
        font-size: 1.8rem;

        @include respond("tab-port") {
          font-size: 1.8rem;
        }

        @include respond("phone") {
          font-size: 1.4rem;
        }

        @include respond("desktop") {
          font-size: 2rem;
        }

        .css-26l3qy-menu {
          margin-top: 3px !important;
          border-radius: 8px !important;
        }
      }

      .dropdown-row > div {
        border-color: #353535 !important;
        border-radius: 16px !important;
        min-height: 33px !important;
        background-color: #ffffff !important;

        .css-qc6sy-singleValue {
          font-size: 1.8rem !important;
          padding-top: 1px;
          font-weight: 100;
          color: #1d1b20 !important;
        }

        .css-1okebmr-indicatorSeparator {
          display: none !important;
        }

        .css-1gtu0rj-indicatorContainer,
        .css-tlfecz-indicatorContainer {
          padding: 0 !important;
        }

        svg {
          display: inline-block;
          fill: #1d1b20 !important;
          border-left: 0.7rem solid transparent;
          border-right: 0.7rem solid transparent;
          border-top: 0.7rem solid #000000;
          width: 0;
          height: 0;
          margin-right: 9px;
        }
      }
    }

    .class-level-dropdown {
      margin-top: 15px;
    }
  }

  .table-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    text-align: center;
    height: calc(100% - 7.5rem);

    .sort-container {
      position: relative;

      .sort-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 850;
        font-size: 2rem;

        @include respond("phone") {
          font-size: 1.6rem;
        }
      }

      .sort-menu-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 0;
        margin-right: 1.5rem;
        border: 1px solid rgba(85, 85, 85, 1);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 5px;
        background-color: white;
        height: 11.625rem;
        overflow-y: auto;

        // @include respond("phone") {
        //   height:18.625rem;
        // }

        @media only screen and (max-height: 600px) {
          height: 40vh;
        }

        @media only screen and (min-height: 601px) and (max-height: 700px) {
          height: 45vh;
        }

        @media only screen and (min-height: 701px) {
          height: auto;
        }

        .menu-item {
          display: flex;
          justify-content: space-between;
          min-width: 20rem;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(85, 85, 85, 1);
          }

          &.ascending-menu-item {
            border-top: 2px solid rgba(85, 85, 85, 1);
          }

          font-weight: 850;
          font-size: 1.8rem;

          @include respond("phone") {
            font-size: 1.6rem;
          }

          &.apply-button {
            font-size: 1.7rem;
            justify-content: center;
          }

          &.sort-by-header {
            font-size: 1.6rem;
            justify-content: center;
            color: #818080;
            opacity:1 ;
          }
        }
      }
    }

    .merch-sales-label-container {
      display: flex;
      justify-content: space-around;

      .merch-sales-label {
        font-size: 1.8rem;

        @include respond("tab-port") {
          font-size: 1.8rem;
        }

        @include respond("phone") {
          font-size: 1.4rem;
        }

        color: #555555;
      }
    }

    .border-row {
      width: 100%;
      height: 1px;
      margin-top: 7px;
      background-color: #bfb2b2;
    }

    .top-border-row {
      background-color: #0066ff;
    }

    .sku-border-row {
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: #bfb2b2;
    }

    .merch-title {
      padding-right: 5px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      flex-shrink: 0;
      font-weight: 850;
      font-size: 1.8rem;

      @include respond("tab-port") {
        font-size: 2rem;
      }

      @include respond("phone") {
        font-size: 1.6rem;
      }
    }

    .merch-row-container {
      display: flex;
      flex-direction: row;
      width: 100%;

      .merch-Subtitle {
        padding-right: 5px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        flex-shrink: 0;
        font-size: 1.7rem;
        font-weight: bolder;
        text-align: start;
        width: 100%;
        color: #202020;

        @include respond("tab-port") {
          font-size: 1.8rem;
        }

        @include respond("phone") {
          font-size: 1.4rem;
        }
      }
    }

    .merch-sales-container {
      flex-shrink: 0;

      .merch-sales-value {
        font-size: 1.8rem;

        @include respond("tab-port") {
          font-size: 1.8rem;
        }

        @include respond("phone") {
          font-size: 1.4rem;
        }
      }

      .merch-sales-label {
        font-size: 1.8rem;

        @include respond("tab-port") {
          font-size: 1.8rem;
        }

        @include respond("phone") {
          font-size: 1.4rem;
        }

        color: #555555;
      }
    }

    .merch-sku-container {
      flex-shrink: 0;
      text-align: center;

      .merch-sku-value {
        font-size: 1.6rem;

        @include respond("tab-port") {
          font-size: 1.7rem;
        }

        @include respond("phone") {
          font-size: 1.3rem;
        }
      }

      .merch-sku-label {
        font-size: 1.5rem;

        @include respond("tab-port") {
          font-size: 1.6rem;
        }

        @include respond("phone") {
          font-size: 1.2rem;
        }

        color: #555555;
      }
    }

    .table-content {
      .sort-container {
        position: relative;

        .sort-box {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-weight: 850;
          font-size: 2rem;

          @include respond("phone") {
            font-size: 1.6rem;
          }
        }

        .sort-menu-container {
          position: absolute;
          display: flex;
          flex-direction: column;
          right: 0;
          margin-right: 1.5rem;
          border: 1px solid rgba(85, 85, 85, 1);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          border-radius: 5px;
          background-color: white;
          height: 11.625rem;
          overflow-y: auto;

          // @include respond("phone") {
          //   height:14.625rem;
          // }

          @media only screen and (max-height: 600px) {
            height: 40vh;
          }

          @media only screen and (min-height: 601px) and (max-height: 700px) {
            height: 45vh;
          }

          @media only screen and (min-height: 701px) {
            height: auto;
          }

          // @media only screen and (min-width:1024px) and (max-width:1279px) {
          //   height:18.5em !important;
          // }

          .menu-item {
            display: flex;
            justify-content: space-between;
            min-width: 20rem;

            &:not(:last-child) {
              border-bottom: 1px solid rgba(85, 85, 85, 1);
            }

            &.ascending-menu-item {
              border-top: 2px solid rgba(85, 85, 85, 1);
            }

            font-weight: 850;
            font-size: 1.8rem;

            @include respond("phone") {
              font-size: 1.6rem;
            }

            &.apply-button {
              font-size: 1.7rem;
              justify-content: center;
            }

            &.sort-by-header {
              font-size: 1.6rem;
              justify-content: center;
              color: #818080;
            }
          }
        }
      }
    }

    .sku-table-container {
      height: calc(100% - 90px);

      @include respond("phone") {
        height: calc(100% - 80px);
      }

      text-align: center;
      overflow: auto;

      .merch-sticky-container {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: #ffffff;
      }

      .merch-sku-label-container {
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid #0066ff;

        .merch-sku-label {
          font-size: 1.8rem;
          padding: 0 0 5px 0;

          @include respond("tab-port") {
            font-size: 1.6rem;
          }

          @include respond("phone") {
            font-size: 1.2rem;
          }

          color: #555555;
        }
      }
    }

    .class-sku-table-container {
      height: calc(100% - 14.5rem);

      @include respond("tab-port") {
        height: calc(100% - 13.5rem);
      }

      @media only screen and (min-width: 911px) and (max-width: 920px) {
        height: calc(100% - 11.5rem);
      }

      @include respond("phone") {
        height: calc(100% - 11.5rem);
      }

      @media only screen and (max-width: 396px), (min-width: 512px) and (max-width: 540px) {
        height: calc(100% - 12.5rem);
      }

      // .sort-container {
      //   position: relative;

      //   .sort-box {
      //     display: flex;
      //     justify-content: flex-end;
      //     align-items: center;
      //     font-weight: 850;
      //     font-size: 2rem;

      //     @include respond("phone") {
      //       font-size: 1.6rem;
      //     }
      //   }

      //   .sort-menu-container {
      //     position: absolute;
      //     display: flex;
      //     flex-direction: column;
      //     right: 0;
      //     margin-right: 1.5rem;
      //     border: 1px solid rgba(85, 85, 85, 1);
      //     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      //     border-radius: 5px;
      //     background-color: white;
      //     height: 20vh;
      //     overflow-y: auto;

      //     // @include respond("phone") {
      //     //   height:18.625rem;
      //     // }

      //     @media only screen and (max-height: 600px) {
      //       height: 15vh;
      //     }

      //     @media only screen and (min-height: 601px) and (max-height: 700px) {
      //       height: 20vh;
      //     }

      //     @media only screen and (min-height: 701px) {
      //       height: 30vh;
      //     }

      //     .menu-item {
      //       display: flex;
      //       justify-content: space-between;
      //       min-width: 20rem;

      //       &:not(:last-child) {
      //         border-bottom: 1px solid rgba(85, 85, 85, 1);
      //       }

      //       &.ascending-menu-item {
      //         border-top: 2px solid rgba(85, 85, 85, 1);
      //       }

      //       font-weight: 850;
      //       font-size: 1.8rem;

      //       @include respond("phone") {
      //         font-size: 1.6rem;
      //       }

      //       &.apply-button {
      //         font-size: 1.7rem;
      //         justify-content: center;
      //       }

      //       &.sort-by-header {
      //         font-size: 1.6rem;
      //         justify-content: center;
      //         color: #818080;
      //       }
      //     }
      //   }
      // }
    }
  }

  .merch-detail-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    height: 50%;
  }
}

.merch-noData-card {
  display: flex;
  flex-direction: column;
  height: 10rem;
  background: white;
  text-align: center;

  .no-data-row {
    .label {
      justify-content: center;
      font-weight: 900;
      font-size: 2rem;
      color: #010101;
      letter-spacing: 0.02em;
      padding-top: 4rem;
    }
  }
}
.merch-details-table {
  &:has(.merch-store-total-row) {
    overflow: hidden !important;
  }
}

// .sort-container {
  //  position: relative;

  // .sort-box {
  //   display: flex;
  //   justify-content: flex-end;
  //   align-items: center;
  //   font-weight: 850;
  //   font-size: 2rem;

  //   @include respond("phone") {
  //     font-size: 1.6rem;
  //   }

  //   // Arrow icon styling
  //   &::after {
  //     content: '';
  //     display: inline-block;
  //     margin-left: 0.5rem;
  //     width: 0;
  //     height: 0;
  //     border-left: 0.4rem solid transparent;
  //     border-right: 0.4rem solid transparent;
  //     border-top: 0.6rem solid #000;
  //     transition: transform 0.3s ease;
  //   }

  //   // Rotate the arrow when sorted ascending or descending
  //   &.ascending::after {
  //     transform: rotate(180deg);
  //   }

  //   &.descending::after {
  //     transform: rotate(0deg);
  //   }
  // }

  // .sort-menu-container {
  //   position: absolute;
  //   display: flex;
  //   flex-direction: column;
  //   right: 0;
  //   margin-right: 1.5rem;
  //   border: 1px solid rgba(85, 85, 85, 1);
  //   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  //   border-radius: 5px;
  //   background-color: white;
  //   height: 11.625rem;
  //   overflow-y: auto;

  //   .menu-item {
  //     display: flex;
  //     justify-content: space-between;
  //     min-width: 10rem;

  //     &:not(:last-child) {
  //       border-bottom: 1px solid rgba(85, 85, 85, 1);
  //     }

  //     font-weight: 850;
  //     font-size: 1.8rem;

  //     @include respond("phone") {
  //       font-size: 1.6rem;
  //     }

  //     &.apply-button {
  //       font-size: 1.7rem;
  //       justify-content: center;
  //     }

  //     &.sort-by-header {
  //       font-size: 1.6rem;
  //       justify-content: center;
  //       color: #818080;
  //     }
  //   }
  // }
// }

.sort-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .sort-box {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: bold;
    .sort-button {
      font-weight: 850;
      color: rgba(0, 0, 0, 0.87);
      font-size: 1.6rem;
      font-family: Franklin Gothic Pro, Arial, sans-serif !important;
      //   font-size: 2rem
      text-transform: none;
    }
    .sort-arrow {
      margin-left: 0.5rem;
      transform: rotate(0deg); // For default position
    }

    &:hover .sort-arrow {
      transform: rotate(180deg); // To rotate arrow when sorting changes
    }
  }
}
.sort-menu-list-wrapper {
  .MuiPopover-paper {
    max-height: 200px !important;
    width: 150px;

    ul {
      li {
        justify-content: space-between;
        // border-bottom: 1px solid #555;
        padding-top: 0px;
        padding-bottom: 0px;
        font-weight: 850;
        font-size: 1.2rem;
        font-family:
          Franklin Gothic Pro,
          sans-serif !important;
        min-height: 33px !important;
        &.apply-button {
          font-size: 1.4rem;
          justify-content: center;
          // border: none;
          color: rgb(0, 102, 255);
        }

        &.sort-by-header {
          font-size: 1.6rem;
          justify-content: center;
          color: #818080;
        }
        &:not(:last-child) {
          border-bottom: 1px solid rgba(85, 85, 85, 1);
        }
        &.ascending-menu-item {
          border-top: 1px solid rgba(85, 85, 85, 1);
        }
      }
    }
  }
}
